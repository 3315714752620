<template>
  <div class="partners">
    <transition name="fade" mode="out-in">
      <base-loader v-if="!instruction" class="personal__loader" />
      <div v-else>
        <card-block :title="$t('config')" class="partners__block">
          <main-card class="partners__card">
            <notification-settings
              :list="noticeList"
              @change="onChange('settings', $event)"
              @ready="onInitialBackupFormData($event)"
            ></notification-settings>
            <template #footerEnd>
              <base-button
                :loading="formLoading"
                :disabled="hasChangesNootify"
                @click="submit(formData, 'formLoading')"
              >
                {{ $t('submit') }}
              </base-button>
            </template>
          </main-card>
        </card-block>
        <card-block :title="$t('title')" class="partners__block">
          <main-card class="partners__card">
            <div class="partners__telegram">
              <label class="standart-title">{{ $t('hardcode.title') }}</label>
            </div>
            <br />
            <div class="partners__telegram">
              <label class="standart-text">{{ $t('hardcode.text11') }}</label>
              <plain-button
                color="primary"
                href="https://telegramzy.ru/nik-v-telegramm/"
                target="_blank"
                class="partners__telegram-btn"
              >
                пользователя Telegram
              </plain-button>
              <label class="partners__telegram-btn standart-text">{{
                $t('hardcode.text12')
              }}</label>
              <label class="partners__telegram-btn standart-text">{{
                $t('hardcode.text13')
              }}</label>

              <!--              <p class="standart-text" v-html="textLinkUserTelegram"></p>-->
            </div>
            <!--            <br />-->
            <div class="partners__telegram">
              <!--                size="small"-->
              <base-input
                v-model="telegramId.value"
                :placeholder="telegramId.placeholder"
                :hint="telegramId.hint"
                class="partners__telegram-btn"
                @input="onChange('telegram', $event)"
              >
              </base-input>
              <base-button
                :loading="telegramLoading"
                :disabled="!hasChangesTelegram"
                class="partners__telegram-btn"
                @click="submit(telegramData, 'telegramLoading')"
              >
                {{ $t('submit') }}
              </base-button>
            </div>
            <br />
            <div class="partners__telegram">
              <label class="standart-text">{{ $t('hardcode.text2') }}</label>
            </div>

            <!--            <br />-->
            <div class="partners__telegram">
              <plain-button
                color="primary"
                href="https://t.me/RusonyxGroupBot"
                target="_blank"
                class="partners__telegram-btn"
              >
                RusonyxGroupBot
              </plain-button>
              <main-label
                :color="telegramBotStatus.color"
                theme="plain"
                class="partners__telegram-btn"
              >
                {{ telegramBotStatus.label }}
              </main-label>
            </div>
            <div class="partners__telegram">
              <label class="partners__telegram-btn standart-text">{{
                $t('hardcode.text31')
              }}</label>
              <label class="partners__telegram-btn standart-text">
                <b>{{ $t('hardcode.text32') }}</b>
              </label>
            </div>
            <br />
            <div class="partners__telegram">
              <label class="standart-text">{{ $t('hardcode.text4') }}</label>
            </div>
          </main-card>
        </card-block>
      </div>
    </transition>
  </div>
</template>

<script>
import CardBlock from '../components/CardBlock';
import MainCard from '@/components/MainCard/MainCard';
import BaseInput from '@/components/BaseInput/BaseInput.vue';
// import CheckboxBlock from '@/components/Configurator/components/CheckboxBlock.vue';

import NotificationSettings from '@/layouts/Profile/Main/components/NotificationSettings';
import mixin from '@/layouts/Profile/mixins';
import { isEqual } from 'lodash';
import MainLabel from '@/components/Labels/MainLabel';
export default {
  name: 'Telegram',
  components: {
    MainLabel,
    NotificationSettings,
    CardBlock,
    MainCard,
    BaseInput /*, CheckboxBlock*/,
  },
  mixins: [mixin],
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formData: {},
      backupFormData: {},
      telegramData: {},
      backupTelegramData: {},
      hasChangesNootify: false,
      hasChangesTelegram: false,
      formLoading: false,
      settingsModal: null,
      timerCnt: 0,
      startCycling: false,
      telegramLoading: false,
      fetchingTimer: null,
      config: {
        values: {
          on: true,
          off: false,
        },
        value: 'off',
        trueValue: 'on',
        falseValue: 'off',
      },
    };
  },
  computed: {
    noticeList() {
      const list = this.profile.noticeList.map(element => {
        return {
          ...element,
          list: element.list.filter(
            subElement => subElement.key === 'ntemail' || subElement.key === 'ntmessenger'
          ),
        };
      });
      return list || [];
    },
    textLinkUserTelegram() {
      return '1. Указать имя <b><a href="https://telegramzy.ru/nik-v-telegramm/" target="_blank">пользователя Telegram</a></b> (без @), который будет использовать бота, и подтвердить изменения.';
    },
    profile() {
      return this.$store.state.moduleProfile.profile;
    },

    instruction() {
      if (this.profile && this.profile.instruction && this.profile.instruction.label) {
        // console.log(this.sanitize(this.profile.instruction.label));
        let tempHTML = this.profile.instruction.label
          // .replace(
          //   'имя пользователя',
          //   '<a href="https://telegramzy.ru/nik-v-telegramm/" target="_blank">имя пользователя</a>'
          // )
          // .replace('Подтверждено', '<span style="color: #4cbf86">Настроен</span>')
          // .replace('Настраивается', '<span style="color: #d9a406">Требуется настройка</span>')
          // .replaceAll('<br/>', '<br/><br/>')
          .split('<br/>Поддержка<br/>')[0];
        // `<a href="${this.host}/billmgr?func=${item.action}&elid=${item.param.elid}">${item.name}</a>`;

        return tempHTML;
        // return this.profile.instruction.label;
      }
      return null;
    },
    telegramBotStatus() {
      if (this.profile && this.profile.instruction && this.instruction) {
        // let status;
        // console.log(this.instruction);
        if (this.instruction.includes('Настраивается')) {
          return {
            label: 'Настраивается',
            color: 'warning',
          };
        } else if (this.instruction.includes('Подтверждено'))
          return {
            label: 'Настроен',
            color: 'success',
          };
        else if (this.instruction.includes('Требуется настройка')) {
          return {
            label: 'Требуется настройка',
            color: 'error',
          };
        }
        // return status;
      }
      return null;
    },
    telegramId() {
      return this.profile.telegramId;
    },
    telegramSupport() {
      return this.profile.telegramSupport;
    },
  },
  watch: {
    telegramId: {
      handler: function (event) {
        if (event && event.value) {
          this.telegramData.telegram_id = event.value;
          this.backupTelegramData.telegram_id = event.value;
        }
      },
      immediate: true,
    },
    // fetchingTimer(event) {
    //   console.log('fetchingTimer', event);
    // },
    // telegramBotStatus: {
    //   handler: function (event) {
    //     console.log(event.color);
    //     if (event.color === 'warning' && !this.fetchingTimer) {
    //       console.log('cycle');
    //       this.cycleFetching(true);
    //     } else if (event.color !== 'warning' && this.fetchingTimer) {
    //       console.log('else', this.fetchingTimer);
    //       if (this.fetchingTimer) clearTimeout(this.fetchingTimer);
    //     }
    //   },
    //   deep: true,
    // },

    telegramSupport: {
      handler: function (event) {
        if (event && event.value) {
          this.config.value = event.value;
          this.telegramData.telegram_support = event.value;
          this.backupTelegramData.telegram_support = event.value;
        }
      },
      immediate: true,
    },
  },
  mount() {
    // this.cycleFetching();
  },
  // beforeDestroy() {
  //   if (this.fetchingTimer) clearTimeout(this.fetchingTimer);
  // },
  methods: {
    onChange(item, event) {
      if (item === 'settings') {
        this.formData = Object.assign(this.formData, event);
        this.hasChangesNootify = isEqual(this.formData, this.backupFormData);
      }
      // if (item === 'checked') {
      //   this.telegramData.telegram_support = event.value;
      //   this.hasChangesTelegram = !this.isObjectsEqual(this.telegramData, this.backupTelegramData);
      // }
      if (item === 'telegram') {
        // console.log(event);
        // this.telegramData.telegram_id = event.target.value;
        this.telegramData.telegram_id = event;
        this.hasChangesTelegram = !this.isObjectsEqual(this.telegramData, this.backupTelegramData);
      }
    },
    onInitialBackupFormData(event) {
      this.backupFormData = Object.assign({}, event);
    },
    isObjectsEqual(obj1, obj2) {
      return Object.keys(obj1).every(k => obj2[k] && obj1[k] === obj2[k]);
    },
    submit(payload, loading) {
      // console.log(payload);
      const data = this.getPreparedFormData(payload);
      this[loading] = true;
      let res = 'fail';
      this.updateProfileInfo(data, 'mediator')
        .then(() => (res = 'success'))
        .catch(e => {
          this[loading] = false;
          this.telegramId.value = this.backupTelegramData.telegram_id;
          this.telegramData.telegram_id = this.backupTelegramData.telegram_id;
          this.hasChangesTelegram = !this.isObjectsEqual(
            this.telegramData,
            this.backupTelegramData
          );
          this.showResultModal(e.msg);
          if (this.settingsModal) this.$modals.close({ name: this.settingsModal.name });
        })
        .finally(() => {
          if (res === 'success')
            setTimeout(() => {
              this[loading] = false;
              if (payload.telegram_id) {
                this.backupTelegramData.telegram_id = payload.telegram_id;
                this.hasChangesTelegram = !this.isObjectsEqual(
                  this.telegramData,
                  this.backupTelegramData
                );
              }
              this.showResultModal(this.$t(`res.${res}`));
              if (this.settingsModal) this.$modals.close({ name: this.settingsModal.name });
            }, 500);
        });
    },
    // fetchUserParams() {
    //   return this.$store.dispatch('moduleProfile/fetchUserParams');
    // },
    // async cycleFetching(needUpdate = false) {
    //   this.timerId = setTimeout(() => {
    //     if (needUpdate) {
    //       this.timerCnt = 4;
    //       // console.log('needUpdate');
    //       this.$store.dispatch('moduleProfile/fetchUserParams').then(async data => {
    //         // console.log(data);
    //         if (this.telegramBotStatus.color === 'warning') await this.cycleFetching(true);
    //       });
    //     } else {
    //       // this.isStartUpdateTariff = true;
    //       this.$store.dispatch('moduleProfile/fetchUserParams');
    //     }
    //   }, 1000 * this.timerCnt);
    // },
    // cycleFetching() {
    //   this.fetchUserParams();
    //   this.fetchingTimer = setTimeout(this.fetchUserParams, 10000);
    // },
  },
};
</script>

<i18n>
  {
    "ru": {
      "config": "Какие уведомления вы хотите получать:",
      "submit": "Подтвердить",
      "title": "Настройки работы с Telegram",
      "hardcode": {
        "title": "Для работы с Telegram ботом потребуется:",
        "text11": "1. Указать имя ",
        "text12": " (без @), ",
        "text13": "который будет использовать бота, и подтвердить изменения.",
        "text2": "2. Открыть нашего бота в Telegram:",
        "text31": "Отправить боту команду: ",
        "text32": "/start",
        "text4": "3. Отметить Telegram в настройках уведомлений сверху страницы."

      },
      "res": {
        "success": "Настройки успешно сохранены.",
        "fail": "К сожалению, что-то пошло не так. Попобуйте позднее"
      }

    }
  }
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.partners {
  &__telegram {
    display: flex
    align-items: center
    //align-items: flex-start
    flex-wrap: wrap;
    margin-bottom: 0.5rem;
    &-btn {
      margin-left: 1rem
    }

  }
  &__card {
    margin-bottom: 1.75rem;
    +breakpoint(md-and-up) {
      max-width: 40rem;
    }
  }
  &__desc {
    //max-width: 32em;
  }

  &__links {
    margin-top: 1.5rem;
    flexy(flex-start, flex-start, nowrap, column);

    +breakpoint(sm-and-up) {
      margin-top: 2.5rem;
    }
  }

  &__field {
    opacity: 1.0;
  }

  &__link {
    text-align: left;
    & + & {
      margin-top: 1rem;
    }
  }

}
</style>
